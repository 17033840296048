<template>
  <div class="contaner">
    <div v-if="dataList.user.union_member==0&&queryList.index">
      <div>
        <div class="iconImg">
          <img src="../../assets/failIcon.png" />
          <div class="title2">请先成为会员</div>
          <div class="text">当前不是工会会员，无法进行操作</div>
          <div class="successBtn" @click="submit(2)">点击前往申请工会会员</div>
        </div>
      </div>
    </div>
    <div v-if="dataList.user.union_member==0&&!queryList.index">
      <div>
        <div class="iconImg">
          <img src="../../assets/failIcon.png" />
          <div class="title2">申请失败</div>
          <div class="text">当前不是工会会员，无法进行股东报名</div>
          <div class="successBtn" @click="submit(2)">点击前往申请工会会员</div>
        </div>
      </div>
    </div>
    <div v-if="dataList.user.union_member!=0">
      <div v-if="dataList.user.union_shareholder!=2&&dataList.user.union_member_freeze!=1&&dataList.audit">
        <div class="iconImg" v-if="dataList.audit.audit_state==2">
          <img src="../../assets/audit_success.png" />
          <div class="title2">无需重复提交</div>
          <div class="text">您已是股东,无需申请</div>
          <div class="successBtn" @click="submit(0)">点击进入股东中心</div>
        </div>

        <div class="iconImg" v-if="dataList.audit.audit_state==0||dataList.audit.audit_state==1">
          <img src="../../assets/audit_error.png" />
          <div class="title2">正在申请中</div>
          <div class="text2">2-3个工作日内完成请您耐心等候</div>
          <div class="errorBtn" @click="lookDetail">查看审核详情</div>
          <!-- <div class="message" @click="lookDetail">查看审核详情 ></div> -->
        </div>
      </div>

      <div v-if="dataList.user.union_shareholder!=0">
        <div class="iconImg">
          <img src="../../assets/audit_success.png" />
          <div class="title2">无需重复提交</div>
          <div class="text">您已是股东,无需申请</div>
          <div class="successBtn" @click="submit(0)">点击进入股东中心</div>
        </div>
      </div>

      <div v-if="dataList.user.member.is_shareholder==1&&dataList.user.union_shareholder==0&&!dataList.audit">
        <div class="iconImg">
          <img src="../../assets/failIcon.png" />
          <div class="title2">请先申请股东</div>
          <div class="text">您还不是购购股东，请去申请</div>
          <div class="successBtn" @click="submit(3)">点击前往申请股东</div>
        </div>
      </div>

      <div v-if="dataList.user.member.is_shareholder==0&&dataList.user.union_shareholder==0&&!dataList.audit">
        <div class="iconImg">
          <img src="../../assets/failIcon.png" />
          <div class="title2">请先申请股东</div>
          <div class="text">您还不是购购股东，请去申请</div>
          <div class="successBtn" @click="submit(3)">点击前往申请股东</div>
        </div>
      </div>

      <div v-if="dataList.user.union_shareholder_expire_at?new Date(dataList.user.union_shareholder_expire_at).getTime()>new Date().getTime():false">
        <div class="iconImg">
          <img src="../../assets/failIcon.png" />
          <div class="title2">申请失败</div>
          <div class="text" style="margin-top:30px;color:red">账号冻结无法申请</div>
          <!-- <div class="successBtn" @click="submitBtn">点击去激活</div> -->
        </div>
      </div>
    </div>

  </div>
</template>
<script scoped type="">
// import TencentCaptcha from 'vue-tencent-captcha';
// import store from '../../store/index.js';
// import captcha from "@/components/captcha";
import * as apiCommon from "@/api/common";
export default {
  components: {
    // captcha
  },
  data() {
    return {
      value: "",
      codeData: "",
      captchaConfig: {
        title: "获取验证码",
        mobile: "",
        type: 6,
        code: "",
        imgToken: ""
      },
      tableData: {
        union_audit: {},
        user: {}
      },
      dataList: {
        user: {
          member: {}
        },
        audit: {
          audit_state: ""
        }
      },
      queryList:{}
    };
  },
  async created() {
    document.title = "审核记录";
    // 判断用户
    // this.applyUser();
    // this.getToken();
    this.queryList = this.$route.query;
    console.log(this.queryList);
    // 判断用户
    await this.applyUser();
  },
  methods: {
    async applyUser() {
      let res = await apiCommon.getSetting();
      console.log(res, "res");
      if (res.code == 0) {
        this.dataList = res.data;
        if(!this.dataList.audit&&this.dataList.user.union_member!=0){
        // this.$router.push("../lador/ladorLogin");
        return false
      }
        this.$forceUpdate();
        console.log(this.dataList);
      }
    },
    submit(index) {
      if(index==2){
        this.$router.push("../index/index");
        return false
      }
      if(index==3){
        this.$router.push("../shareholders/index");
        return false
      }
      if (index == 0) {
        this.$router.push("../lador/ladorLogin");
      } else {
        this.$router.push(
          `./modification?id=${this.tableData.union_audit.audit_id}`
        );
      }
    },
    lookDetail() {
      if(!this.dataList.audit&&this.dataList.user.union_member!=0){
        this.$router.push("../lador/ladorLogin");
        return false
      }
      if (this.dataList.audit.audit_type == 1) {
        this.$router.push(
          `../shareholders/online/onlineDetails?audit_id=${
            this.dataList.audit.audit_id
          }`
        );
      } else {
        this.$router.push(
          `../shareholders/offline/flow?audit_id=${
            this.dataList.audit.audit_id
          }`
        );
      }
    },
    submitBtn() {
      this.$router.push("../index/activate");
    }
  }
};
</script>
<style scoped>
.iconImg {
  margin: 300px auto 0;
  text-align: center;
}
.iconImg img {
  width: 180px;
  height: 180px;
  margin: 0 285px 0;
}
.title2 {
  font-size: 40px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #101010;
  margin: 22px 0 10px;
}
.text {
  font-size: 35px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #07c160;
  margin: 10px 0;
}
.text1 {
  font-size: 38px;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 22px;
}
.successBtn {
  background-color: #07c160;
  width: 400px;
  height: 90px;
  line-height: 90px;
  text-align: center;
  font-size: 40px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin: 300px auto 0;
  padding:20px;
}
.text2 {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #faa030;
  margin-top: 22px;
}
.errorBtn {
  background-color: #faa030;
  width: 350px;
  height: 90px;
  line-height: 90px;
  text-align: center;
  font-size: 40px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin: 300px auto 0;
}
.message {
  color: #faa030;
  font-size: 30px;
  margin-top: 24px;
}

.contaner {
  background-color: #fff;
}
</style>
